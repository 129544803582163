"use strict";

// import "core-js/stable";
// import "regenerator-runtime/runtime";

// Elements
const body = document.querySelector("body");
const header = document.querySelector("header");
const navContainer = document.querySelector(".nav");
const footerContainer = document.querySelector(".footer");
const mainSection1 = document.getElementById("section--1");
const mainSection2 = document.getElementById("section--2");
const mainSection3 = document.getElementById("section--3");
const iconContainer = document.querySelector(".icon-container");
const btnFormSubmit = document.querySelector(".btn__submit");
const loadingEl = document.querySelector(".loading");
const navBack = document.querySelector(".nav__back");
const goToTop = document.querySelector(".go-to-top");
const navToggler = document.querySelector(".nav__toggler");
const navTogglerClose = document.querySelector(".nav__toggler--close");
const navLinks = document.querySelector(".nav__links");
const navBtn = document.querySelector(".nav__btn");
const allNavLinks = document.querySelectorAll(".nav__link");
const allSections = document.querySelectorAll(".section");
const allDividers = document.querySelectorAll(".divider");
const maxMobileWidth = 980;

// Variables
const navHeight = getComputedStyle(navContainer).height;

const navMobileHidden = function () {
  navLinks.classList.add("collapsible");
  navLinks.classList.remove("collapsible__expanded");
  navToggler.classList.remove("hidden");
  navTogglerClose.classList.add("hidden");
};

const navMobileToggle = function () {
  navLinks.classList.toggle("collapsible__expanded");
  navLinks.classList.toggle("collapsible");
  navToggler.classList.toggle("hidden");
  navTogglerClose.classList.toggle("hidden");
};

// IMPLEMENTATIONS
window.addEventListener("load", function (e) {
  fadeOutEffect();

  if (window.innerWidth <= maxMobileWidth) {
    navMobileHidden();
  }
});

window.addEventListener("resize", function () {
  if (window.innerWidth <= maxMobileWidth) {
    navMobileHidden();
    navContainer.style.padding = "0";
  } else {
    navLinks.classList.remove("collapsible");
    navToggler.classList.add("hidden");
    navTogglerClose.classList.add("hidden");
    if (navContainer.classList.contains("sticky"))
      navContainer.style.padding = "1rem 4rem";
    else navContainer.style.padding = "3rem 4rem";
  }
});

const scrollTo = function (e, cn) {
  const sectionScrollTo = e.target.closest(`.${cn}`).dataset.scrollTo;
  const sectionEl = document.querySelector(`#${sectionScrollTo}`);
  sectionEl.scrollIntoView({ behavior: "smooth" });
};

navContainer.addEventListener("click", function (e) {
  e.preventDefault();

  let className;
  if (e.target.closest(".nav__link")?.classList.contains("nav__link"))
    className = "nav__link";
  else if (e.target.closest(".nav__btn")?.classList.contains("nav__btn"))
    className = "nav__btn";
  else return;

  scrollTo(e, className);
});

footerContainer.addEventListener("click", function (e) {
  if (e.target.classList.contains("address")) return;
  e.preventDefault();
  let className;
  if (e.target.closest(".footer__link")?.classList.contains("footer__link"))
    className = "footer__link";
  scrollTo(e, className);
});

btnFormSubmit?.addEventListener("mouseover", function () {
  const iconsArrow = this.querySelectorAll(".icon-arrow");
  const btnLabel = this.querySelector(".btn__label");
  iconsArrow.forEach((el) => {
    el.classList.toggle("hidden");
    el.style.transform = "translateX(10px)";
  });
  btnLabel.style.transform = "translateX(10px)";
});

btnFormSubmit?.addEventListener("mouseout", function () {
  const iconsArrow = this.querySelectorAll(".icon-arrow");
  const btnLabel = this.querySelector(".btn__label");
  iconsArrow.forEach((el) => {
    el.classList.toggle("hidden");
    el.style.transform = "translateX(-10px)";
  });
  btnLabel.style.transform = "translateX(-10px)";
});

const fadeOutEffect = function () {
  const fadeEffect = setInterval(function () {
    if (!loadingEl.style.opacity) {
      loadingEl.style.opacity = 1;
    }
    if (loadingEl.style.opacity > 0) {
      loadingEl.style.opacity -= 0.005;
    } else {
      clearInterval(fadeEffect);
      loadingEl.style.display = "none";
    }
  }, 1);
};

// NAV INTERSECTION OBSERVER

const navObsCallback = function (entries) {
  const [entry] = entries;
  if (!entry.isIntersecting) {
    navContainer.classList.add("sticky");
    if (window.innerWidth > maxMobileWidth) navContainer.style.padding = "1rem 4rem";
  } else {
    navContainer.classList.remove("sticky");
    if (window.innerWidth > maxMobileWidth) navContainer.style.padding = "3rem 4rem";
  }
};

const navObsOption = {
  root: null,
  threshold: 0.1,
};

const navObserver = new IntersectionObserver(navObsCallback, navObsOption);
navObserver.observe(navBack);

// SECTIONS INTERSECTION OBSERVER

const sectionObsCallback = function (entries, observer) {
  const [entry] = entries;
  if (!entry.isIntersecting) entry.target.classList.add("section--hidden");
  else {
    entry.target.classList.remove("section--hidden");
    observer.unobserve(entry.target);
  }
};

const sectionObsOption = {
  root: null,
  threshold: 0.15,
};

const sectionObserver = new IntersectionObserver(
  sectionObsCallback,
  sectionObsOption
);

allSections.forEach((section) => {
  sectionObserver.observe(section);
  section.classList.add("section--hidden");
});

// INK STROKE INTERSECTION OBSERVER
let dividerCounter = 1;

const dividerObsCallback = function (entries, observer) {
  const [entry] = entries;
  while (dividerCounter <= Array.from(allDividers).length) {
    entry.target.id = `anim-${dividerCounter}`;
    if (dividerCounter % 2 === 0) {
      bodymovin.loadAnimation({
        container: document.getElementById(`anim-${dividerCounter}`),
        renderer: "svg",
        loop: "true",
        autoplay: "true",
        path: "../../data_divider_right.json",
      });
    } else {
      bodymovin.loadAnimation({
        container: document.getElementById(`anim-${dividerCounter}`),
        renderer: "svg",
        loop: "true",
        autoplay: "true",
        path: "../../data_divider_left.json",
      });
    }
    dividerCounter++;
    break;
  }
  observer.unobserve(entry.target);
};

const dividerObsOption = {
  root: null,
  threshold: 0.01,
};

const dividerObserver = new IntersectionObserver(
  dividerObsCallback,
  dividerObsOption
);

allDividers.forEach((divider) => {
  dividerObserver.observe(divider);
});

// GO-TO-TOP INTERSECTION OBSERVER
const headerObsCallback = function (entries) {
  const [entry] = entries;
  if (!entry.isIntersecting) goToTop.classList.remove("hidden");
  else goToTop.classList.add("hidden");
};

const headerObsOption = {
  root: null,
  threshold: 0.1,
};

const headerObserver = new IntersectionObserver(
  headerObsCallback,
  headerObsOption
);

headerObserver.observe(header);

goToTop.addEventListener("click", function (e) {
  if (e.target.closest(".go-to-top")?.classList.contains("go-to-top")) {
    window.scrollTo({
      top: 0,
    });
  }
});

// NAV TOGGLER
[navToggler, navTogglerClose].forEach((el) => {
  el.addEventListener("click", function () {
    navMobileToggle();
  });
});

allNavLinks.forEach((item) => {
  item.addEventListener("click", function (e) {
    if (e.target.closest(".nav__link") && window.innerWidth <= maxMobileWidth) {
      navMobileHidden();
    }
  });
});

navBtn.addEventListener("click", function () {
  if (window.innerWidth <= maxMobileWidth) {
    navMobileHidden();
  }
});
